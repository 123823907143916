.ProjectPage
{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100vw;
    color: black;
}

.project-title-bar
{
    position: fixed;
    display: flex;
    width: 85vw;
    height:60px;
    align-items: center;
    padding-left: 20px;
    margin: 20px 0;
    background-color: red;
    border-radius: 30px;

    background: rgba( 255, 255, 255, 0.35 );
    box-shadow: 0 0px 10px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4.5px );
    -webkit-backdrop-filter: blur( 4.5px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.project-description-container
{
    display: flex;
    width: 85vw;
    margin-bottom: 20px;
    color: gray;
    margin-top: 100px;
}

.project-thumbnail-container
{
    display: flex;
    width: 85vw;
    margin-bottom: 20px;
    color: gray;
}
.project-thumbnail
{
    display: flex;
    width: 85vw;
    max-width: 600px;
    height: auto;
    border: 2px solid black;
    border-radius: 10px;
    margin: 0 20px 0 0px;
   /* mask-image: linear-gradient(#000, rgba(0,0,0,0.99), rgba(0,0,0,0)); */
}

.view-proj-btn-container
{
    display: flex;
    flex-direction: column;
    align-items: start;
    text-decoration: none;
    width: 85vw;
    height: 100px;
    margin-bottom: 50px;
}
.view-proj-btn
{
    text-decoration: none;
    background-color: rgb(60, 155, 88);
    padding: 10px 30px 10px 30px;
    color: aliceblue;
    border-radius: 8px;
}