.footer-overlay
{
    content: "";
    background: url("/src/assets/grid.svg");
    z-index: -2;
    opacity: 1;
    filter: invert(1);
    /* background-attachment: fixed; */
}
.Footer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 300px;
    border-top: 2px solid rgb(202, 202, 202);
}

.social-medias-container
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin-bottom: 130px;
    /* height: 200px; */
}
.social-media-icon
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 2px solid rgb(255, 255, 255);
    margin: 10px;
    cursor: pointer;
    border-radius: 10px;
    color: rgb(255, 255, 255);
    background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%);
    transition: .2s ease-in-out;
}
/* .social-media-icon:hover{
    filter: invert();
} */

.copyright-text
{
    display: flex;
    width: 100vw;
    font-size: 16px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-weight: 300;
}

@media screen and (max-width: 800px)
{
    .copyright-text
    {
        font-size: 14px;
        font-weight: 400;
    }
}