/* .HomePage
{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.top-container
{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    border-bottom: 2px solid rgb(224, 224, 224);
}

.home-introduction-section
{
    display: flex;
    height: 80vh;
    width: 100vw;
    max-width: 1000px;
    padding: 100px 0px 100px 0px;
    transition: 1s ease-in-out;
}

.intro-container
{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.intro-greeting
{
    font-size: 50px;
    font-family: "Anton SC", sans-serif;
}
.intro-title
{
    font-size: 60px;
    width: fit-content;
    font-family: "Teko", sans-serif;
    font-optical-sizing: auto;
}
.intro-description
{
    display: flex;
    width: 250px;
    font-size: 14px;
    padding: 10px;
    font-weight: 300;
}
.about-btn
{
    text-decoration: none;
    color: #84c5a5;
    cursor: pointer;
    font-family: "Yanone Kaffeesatz", sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-top: -8px;
    margin-left: -8px;
    z-index: 1;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) } 
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  
  100% { transform: rotate( 0.0deg) }
}
.hi-img
{
    align-self: center;
    margin-left: 15px;
    animation: wave-animation 2.5s infinite;
    transform-origin: 70% 70%;
}


.intro-image-container
{
    display: flex;
    justify-content: end;
    width: 100%;
}

.intro-image
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 250px;
    animation: morph 8s ease-in-out infinite;
    border: 2px solid;
    transition: 1s;
}
@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
        
    }
    
    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
        
    }
   
    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
        
    }
}


@media screen and (max-width: 1155px)
{
    .home-introduction-section
    {
        width: 85vw;
    }
    .intro-image-container
    {
        display: none;
    }
}

 */







.Navbar
{
    position: fixed;
    display: none;
    width: auto;
    align-items: center;
    padding: 10px;
    border-radius: 30px;
    z-index: 10;
    top: 20px;
    left: 75vw;

    background: rgba(255, 255, 255, 0);
    box-shadow: 0 0px 10px 0 rgba(90, 126, 150, 0.205);
    backdrop-filter: blur( 2.5px );
    -webkit-backdrop-filter: blur( 4.5px );
    border: 1px solid rgb(194, 225, 243);

    animation: morph 8s ease-in-out infinite;
}




.Home
{
    /* margin-top: 80px; */
    width: 100vw;
    /* user-select: none; */
}

.top
{
    padding-top: 80px;
    display: flex;
    align-items: top;
    justify-content: center;
    /* background-color: #f5f5f5; */
    /* background-color: white; */
    transition: .5s;
    height: 92vh;
    border-bottom: 2px solid rgb(202, 202, 202);
}
.top::before{
    /* background: radial-gradient(circle, rgba(2, 0, 36, 0) 0, #fafafa 100%); */
    position: absolute;
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    background-attachment: fixed;
}
.top::after{
    content: "";
    background-image: url("/src/assets/grid.svg");
    z-index: -2;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 1;
    filter: invert(1);
    background-attachment: fixed;
}

.top-content
{
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 50px;
    margin-right: 50px;
    width: 70vw;
}

.introduction-section
{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.intro-title-container
{
    display: flex;
    align-items: center;
    height: 280px;
    width: auto;
    transition: .5s;
    overflow: hidden;
}

.intro-title
{
    color: #3a3a3d;
    font-size: 50px;
    font-family: 'Poppins';
    margin-right: 40px;
    transition: .5s;
}

.what-i-do
{
    color: #6e6e6e;
    text-decoration: underline;
    font-size: 20px;
    transition: .3s;
}
.what-i-do::after
{
    content: '';
    animation: work 10s infinite;
}
@keyframes work {
    0% {content: 'Unreal Developer'; color: rgb(105, 114, 197);}
    /* 50% {content: 'Web Dev'; color: rgb(204, 166, 61);}
    75% {content: 'Game Dev'; color: rgb(20, 182, 60);} */
    100% {content: 'ReactJS Developer'; color: #1284c5;}
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}

.hi-img
{
    align-self: center;
    animation: wave-animation 2.5s infinite;
    transform-origin: 70% 70%;
    user-select: none;
}

.subtitle-container
{
    width: auto;
    max-width: 600px;
}

.intro-subtitle
{
    font-size: 20px;
    font-weight: 500;
    color: #818181;
    transition: .5s;
    margin-bottom: 3px;
}

.intro-image-container
{
    display: flex;
    justify-content: center;
    width: auto;
    user-select: none;
}

.intro-image
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    margin-top: 80px;
    animation: morph 8s ease-in-out infinite;
    /* border: 2px solid; */
    transition: 1s;
}

@keyframes morph {
0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    /* border-color: #7cc075; */
}
/* 10% {border-color: #3a3a3d;}
15% {border-color: #7f5a91;}
30% {border-color: #06b17d;} */
50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    /* border-color: rgb(112, 68, 68); */
}
/* 65% {border-color: #4e4e6e;}
80% {border-color: #3a3a3d;} */
100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    /* border-color: #7cc075; */
}
}

@media screen and (max-width: 1200px)
{
    .top
    {
        flex-direction: column;
        justify-content: start;
    }
    .top-content
    {
        width: auto;
    }
    .introduction-section
    {
        margin-left: 0px;
        margin-right: 50px;
    }

    .intro-title-container
    {
        height: 150px;
    }

    .intro-title
    {
        font-size: 25px;
    }
    .intro-image-container
    {
        width: auto;
    }
    .intro-image
    {
        height: 160px;
    }
    .subtitle-container
    {
        width: auto;
    }

}

@media screen and (max-width: 1390px)
{
    .top-content
    {
        grid-template-columns: auto auto;
    }
    .intro-image
    {
        margin: 0;
        margin-top: 100px;
        margin-left: auto;
        margin-right: auto;
        height: 250px;
    }
}

@media screen and (max-width: 780px)
{
    .top
    {
        height: 60vh;
    }
    .intro-image-container
    {
        display: none;
    }
    .intro-subtitle
    {
        font-size: 17px;
    }

    .Navbar
    {
        display: flex;
    }
}

@media screen and (max-width: 430px)
{
    .top-content
    {
        margin-left: 20px;
        margin-right: 20px;
    }
    .intro-title
    {
        font-size: 20px;
    }
    .intro-subtitle
    {
        font-size: medium;
    }
    .introduction-section
    {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media screen and (max-width: 345px)
{
    .intro-title
    {
        font-size: 18px;
    }
    .intro-subtitle
    {
        font-size: 14px;
    }
    .introduction-section
    {
        margin-left: 10px;
        margin-right: 10px;
    }
}