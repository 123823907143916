@import url('https://rsms.me/inter/inter.css');

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Teko:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton+SC&family=Yanone+Kaffeesatz:wght@200..700&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@700&display=swap');


*
{
  overflow-x: hidden;
  -ms-overflow-style: nonej; /* for Internet Explorer, Edge */
  scrollbar-width: nonei; /* for Firefox */
}
*::-webkit-scrollbar{
  /* display: none; */
}

body {
  overscroll-behavior-y: none;
  font-family: 'Inter var', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #ddd;
  /* background: #12071f;
  background: #222229; */
}

.gradient {
  background-image: radial-gradient(
      at 27% 37%,
      hsla(215, 98%, 61%, 1) 0px,
      transparent 0%
    ),
    radial-gradient(at 97% 21%, hsla(125, 98%, 72%, .15) 0px, transparent 50%),
    radial-gradient(at 52% 99%, hsla(354, 98%, 61%, .15) 0px, transparent 50%),
    radial-gradient(at 10% 29%, hsla(256, 96%, 67%, .15) 0px, transparent 50%),
    radial-gradient(at 97% 96%, hsla(38, 60%, 74%, .15) 0px, transparent 50%),
    radial-gradient(at 33% 50%, hsla(222, 67%, 73%, .15) 0px, transparent 50%),
    radial-gradient(at 79% 53%, hsla(343, 68%, 79%, .15) 0px, transparent 50%);

    background-attachment: fixed;
}



.projects-list-gradient {
  background: radial-gradient(592px at 48.2% 50%, rgba(255, 255, 249, 0.6) 0%, rgb(160, 199, 254) 74.6%);
  background: radial-gradient(328px at 2.9% 15%, rgb(191, 224, 251) 0%, rgb(232, 233, 251) 25.8%, rgb(252, 239, 250) 50.8%, rgb(234, 251, 251) 77.6%, rgb(240, 251, 244) 100.7%);
  background: linear-gradient(to top, #dfe9f3 0%, white 100%);
  /* background-attachment: fixed; */
}