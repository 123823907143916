.ProjectCard
{
    display: flex;
    flex-direction: column;
    width: 400px;
    /* min-height: 350px; */
    border-radius: 10px;
    box-shadow: 1px 1px 20px 1px #8888886c;
    cursor: pointer;
    transition: .3s;
    /* background: url('/public/grid.jpg'); */
    -webkit-tap-highlight-color: transparent;
}
.ProjectCard:hover
{
    box-shadow: 1px 20px 40px 10px #8888886c;
    transform: rotateZ(-1deg);
}

.Card-Thumbnail
{
    display: flex;
    width: 100%;
    height: auto;
}

.card-title
{
    color: rgb(0, 0, 0);
    width: fit-content;
    max-width: 85%;
    margin: 10px 0 5px 15px;
    font-size: 20px;
    font-weight: 500;
    /* background-color: rgb(171, 195, 216); */
    background-color: rgb(240, 240, 240);
    padding: 5px;
    border-radius: 8px;
}
.card-description
{
    color: rgb(41, 54, 73);
    color: gray;
    width: 90%;
    margin: 0 0 10px 15px;
    /* background-color: antiquewhite; */
    background-color: rgb(240, 240, 240);
    padding: 5px;
    border-radius: 8px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tags-container
{
    margin: 0 0 15px 15px;
}

.card-tags
{
    color: white;
    background-color: rgb(135, 209, 132);
    width: fit-content;
    padding: 2px 10px 2px 10px;
    border-radius: 8px;
    margin: 0 0 0px 5px;
    transition: .5s;
}
.card-tags:hover
{
    background-color: rgb(96, 197, 96);
}


@media screen and (max-width: 900px)
{
    .ProjectCard
    {
        width: 85vw;
        min-height: 250px;
    }
    .card-description
    {
        width: 85%;
    }
}
