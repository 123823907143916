.projects
{
    width: 100vw;
    height: auto;
    user-select: none;
    margin-bottom: 60px;
    background-color: #ffffff;
    margin: 0;
}

.projects-tabs-container
{
    display: flex;
    justify-content: center;
    margin-left: -40px;
    color: black;
    margin-top: 20px;
}
.projects-tab
{
    display: flex;
    border: 2px solid rgb(218, 217, 217);
    padding: 5px 15px 5px 15px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
}
.projects-tab-selected
{
    display: flex;
    border: 2px solid rgb(0, 0, 0);
    padding: 5px 15px 5px 15px;
    margin: 5px;
    border-radius: 5px;
    /* letter-spacing: 2px; */
    padding: 5px 30px;
    background-color: rgb(204, 236, 218);
    transition: .2s linear;
}

.projects-title-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}
.projects-type-title
{
    color: rgb(0, 155, 64);
}
.projects-heading
{
    display: flex;
    color: rgb(58, 58, 58);
    font-size: 28px;
    font-family: Mulish;
    letter-spacing: 6px;

    /* animation
    color: rgb(83, 83, 83);
    background-image: linear-gradient(90deg, #2b2b2b , #ffffff, #2b2b2b);
    background-repeat: repeat;
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 80% 100%;
    animation: shine 3s ease-in-out infinite; */
}

@keyframes shine {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
} 

.projects-container
{
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: auto auto auto;
    column-gap: 50px;
    row-gap: 50px;
    height: auto;
    min-height: 500px;
    padding-top: 40px;
    padding-bottom: 70px;
    /* margin-top: 20px; */
}



@media screen and (max-width: 1350px)
{
    .projects-container
    {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 900px)
{
    .projects-container
    {
        grid-template-columns: auto;
        row-gap: 20px;
    }
}